import { Component } from '@angular/core';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { PostService } from 'src/app/shared/services/post.service';
import { DirectionService } from '../../../../shared/services/direction.service';

@Component({
    selector: 'app-about-us',
    templateUrl: './page-about-us.component.html',
    styleUrls: ['./page-about-us.component.scss']
})
export class PageAboutUsComponent {
    storage_URL = this.app.storage_url
    carouselOptions = {
        nav: false,
        dots: true,
        responsive: {
            580: { items: 3, margin: 32 },
            280: { items: 2, margin: 24 },
            0: { items: 1 }
        },
        rtl: this.direction.isRTL()
    };

    constructor(
        private direction: DirectionService,
        public post: PostService,
        private app: ApplicationService
    ) { }
}
