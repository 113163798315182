import { Component, Input } from '@angular/core';
import { Brand } from '../../interfaces/brand';
import { Router } from '@angular/router';
import { ShopService } from '../../api/shop.service';
import { RootService } from '../../services/root.service';
import { SettingsService } from '../../services/settings.service';
import { ApplicationService } from '../../api/application.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
  @Input() brands: Brand[] = [];

  constructor(
    private router: Router,
    private shop: ShopService,
    private root: RootService,
    public setting: SettingsService,
    public app: ApplicationService
  ) { }

  filterCategory(ev: any) {
    this.router.navigateByUrl(this.root.category(ev)).then();
  }
  filterByBrand(item) {
    this.router.navigateByUrl(this.root.brand(item)).then();
  }
}
