import { AfterViewInit, Component, ElementRef, Inject, Input, NgZone, OnDestroy, PLATFORM_ID, ViewChild } from '@angular/core';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { fromMatchMedia } from '../../../../shared/functions/rxjs/fromMatchMedia';
import { filter, first, shareReplay, takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/shared/api/application.service';

export type MobileHeaderMode = 'alwaysOnTop' | 'pullToShow';

export type MobileHeaderPosition = 'static' | 'sticky';

export type MobileHeaderVisibility = 'hidden' | 'shown';

@Component({
    selector: 'app-mobile-header',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnDestroy, AfterViewInit {
    @Input() stickyMode: MobileHeaderMode | false = false;

    @ViewChild('panelElement') panelElementRef: ElementRef;

    destroy$: Subject<void> = new Subject<void>();


    get panelElement(): HTMLDivElement {
        return this.panelElementRef?.nativeElement;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        public menu: MobileMenuService,
        public app: ApplicationService,
        public zone: NgZone,
        @Inject(DOCUMENT) private document: Document
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {

    }
    openUrl() {
        const url = "https://dandenong.mymks.com.au/home"
        this.document.location.href = url;

    }

}
