<!-- .block-banner -->
<div class="block block-banner">
  <div class="container">
    <a class="block-banner__body">
      <div
        *ngIf="ad?.length > 0"
        class="block-banner__image block-banner__image--desktop"
        style="background-image: url('{{ storage_URL }}{{
          (ad[0]?.images)[0]
        }}');"
      ></div>
      <div
        *ngIf="mobileAd?.length > 0"
        class="block-banner__image block-banner__image--mobile"
        style="
          background-image: url('{{ storage_URL }}{{
          (mobileAd[0]?.images)[0]
        }}');"
      ></div>
      <!-- <div class="d-lg-block d-none">
        <div class="block-banner__title" *ngIf="ad[0]?.title">
          <br class="block-banner__mobile-br" />
          {{ ad[0]?.title }}
        </div>
        <div class="block-banner__text" *ngIf="ad[0]?.subtitle">
          {{ ad[0]?.subtitle }}
        </div>
      </div>

      <div class="d-lg-block d-lg-none">
        <div class="block-banner__title" *ngIf="mobileAd[0]?.title === ">
          <br class="block-banner__mobile-br" />
          {{ mobileAd[0]?.title }}
        </div>
        <div class="block-banner__text" *ngIf="mobileAd[0]?.subtitle">
          {{ mobileAd[0]?.subtitle }}
        </div>
      </div> -->
      <div class="block-banner__title">
        <br class="block-banner__mobile-br" />
        New Online Store launched
      </div>
      <div class="block-banner__text">Grocery, Entertainment and Dine in</div>
      <div class="block-banner__button" (click)="openUrl()">
        <span class="btn btn-sm btn-primary">Shop Now</span>
      </div>
    </a>
  </div>
</div>
<!-- .block-banner / end -->
