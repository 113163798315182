import { AfterViewInit, Component, Inject, Input, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { RootService } from '../../../../shared/services/root.service';
import { HeaderService } from '../../../../shared/services/header.service';
import { Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DropcartType } from '../dropcart/dropcart.component';
import { OffcanvasCartService } from '../../../../shared/services/offcanvas-cart.service';
import { ApplicationService } from 'src/app/shared/api/application.service';

export type NavStickyMode = 'alwaysOnTop' | 'pullToShow';

@Component({
    selector: 'app-header-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() departments = true;
    @Input() logo = false;
    @Input() search = false;
    @Input() stickyMode: NavStickyMode | false = false;

    destroy$: Subject<void> = new Subject<void>();

    stuckFrom = null;
    staticFrom = null;
    scrollPosition = 0;
    scrollDistance = 0;

    media: Observable<MediaQueryList>;

    dropcartType: DropcartType;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private route: ActivatedRoute,
        private offcanvasCart: OffcanvasCartService,
        public root: RootService,
        public zone: NgZone,
        public header: HeaderService,
        public app: ApplicationService,
        @Inject(DOCUMENT) private document: Document,

    ) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.dropcartType = data.dropcartType || 'dropdown';
        });

    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {

    }

    openUrl() {
        const url = "https://dandenong.mymks.com.au/home"
        this.document.location.href = url;

    }

    onCartClick(): void {
        if (this.dropcartType === 'offcanvas') {
            this.offcanvasCart.open();
        }
    }


    onMouseenter() {
        // // document.getElementById("cartl").click();

        // let element: HTMLElement = document.getElementById('cartl') as HTMLElement;
        // var clickEvent = new MouseEvent("click", {
        //     "view": window,
        //     "bubbles": true,
        //     "cancelable": false
        // });


    }
}
