import { Component } from '@angular/core';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { ApplicationService } from 'src/app/shared/api/application.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    date = new Date();

    constructor(public setting: SettingsService, public app: ApplicationService) { }
}