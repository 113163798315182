<div class="container-fluid">
  <div class="row">
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar collapse">
      <app-side-menu [brands]="brands"></app-side-menu>
    </nav>

    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4 main p-r-0">
      <app-block-slideshow [slides]="slides" *ngIf="slides">
      </app-block-slideshow>
      <app-block-deals-carousel
        *ngIf="app.deals === 1 && deals.length > 0"
        header="DEALS"
        layout="grid-5"
        [products]="deals"
      ></app-block-deals-carousel>
      <ng-container>
        <div class="container">
          <div class="block"></div>
        </div>
      </ng-container>

      <!-- <app-block-brands
        *ngIf="
          app.brand_filter === 1 &&
          loading === false &&
          next_page_url === null &&
          brands.length > 0
        "
        [brands]="brands"
      ></app-block-brands> -->

      <!-- <app-block-slideshow [slides]="slides" *ngIf="slides">
      </app-block-slideshow>
      <app-block-deals-carousel
        *ngIf="app.deals === 1 && deals.length > 0"
        header="DEALS"
        layout="grid-5"
        [products]="deals"
      ></app-block-deals-carousel>
      <div
        class="container max-width"
        [ngClass]="{
          'block-products-carousel--loading': loading
        }"
        infinite-scroll
        [infiniteScrollDistance]="scrollDistance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onScrollDown()"
      >
        <ng-container>
          <div class="container">
            <div class="block">
              <div class="products-view">
                <div class="products-view__loader"></div>

                <div class="products-view__content">
                  <div
                    class="products-view__list products-list"
                    attr.data-layout="grid"
                    attr.data-with-features="true"
                  >
                    <div class="products-list__body">
                      <div
                        *ngFor="let product of products"
                        class="products-list__item"
                      >
                        <app-product-card
                          [product]="product"
                        ></app-product-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="row">
          <!-- <div
            class="col-lg-3 col-xl-2 col-md-3 col-6 px-0 px-sm-2 mb-sm-3 px-1 pb-2"
            *ngFor="let p of products"
          > 

          <!-- <div class="container"> 
          <div class="block-products__body">
            <div class="block-products__list">
              <div
                class="col-lg-3 col-xl-2 col-md-3 col-6 px-0 px-sm-2 mb-sm-3 px-1 pb-2 block-products__list-item"
                *ngFor="let p of products"
              >
                <app-product-card [product]="p"></app-product-card>
              </div>
            </div>
          </div>
      
        </div>
      </div>
      <app-block-brands
        *ngIf="
          app.brand_filter === 1 &&
          loading === false &&
          next_page_url === null &&
          brands.length > 0
        "
        [brands]="brands"
      ></app-block-brands>

      <app-footer
        *ngIf="loading === false && next_page_url === null"
      ></app-footer> -->
    </main>
  </div>
</div>
