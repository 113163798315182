<div
  class="block block-products-carousel"
  [ngClass]="{
    'block-products-carousel--loading': loading,
    'block-products-carousel--has-items': !!columns?.length
  }"
  [attr.data-layout]="layout"
>
  <div [ngClass]="{ container: !withSidebar }" *ngIf="showCarousel">
    <app-block-header
      [header]="header"
      [groups]="groups"
      [arrows]="true"
      (next)="carousel.next()"
      (prev)="carousel.prev()"
      (groupChange)="groupChange.emit($event)"
    ></app-block-header>

    <div class="block-products-carousel__slider" #container>
      <div class="block-products-carousel__preloader"></div>

      <owl-carousel-o
        [options]="carouselOptions"
        [appFakeSlides]="columns.length"
        #fakeSlides="appFakeSlides"
        appOwlPreventClick
        #carousel
      >
        <ng-template *ngFor="let column of columns" carouselSlide>
          <div class="block-products-carousel__column">
            <div
              *ngFor="let product of column"
              class="block-products-carousel__cell"
            >
              <app-product-card-home
                class="border"
                [product]="product"
              ></app-product-card-home>
            </div>
          </div>
        </ng-template>
        <!--suppress AngularUndefinedBinding -->
        <ng-template
          *ngFor="let i of fakeSlides.slides"
          carouselSlide
          [id]="'fake-slide-' + i"
        ></ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
