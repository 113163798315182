import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { ShopService } from 'src/app/shared/api/shop.service';

@Component({
    selector: 'app-header-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
    languages = [
        { name: 'English', image: 'language-1', code: 'en' },
        { name: 'தமிழ்', image: 'language-3', code: 'ta' },
        { name: 'Français', image: 'language-2', code: 'fr' },
    ];

    constructor(
        public translate: TranslateService,
        @Inject(PLATFORM_ID)
        private platformId: any,
        public app: ApplicationService,
        public shop: ShopService
    ) { }
    setLanguage(lang) {
        if (isPlatformBrowser(this.platformId)) {
            this.translate.setDefaultLang(lang);
            this.translate.use(lang);
            localStorage.setItem('lang', lang);
        }
    }
}
