<div class="site-footer">
  <div class="container">
    <div class="site-footer__widgets">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <app-footer-contacts></app-footer-contacts>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <app-footer-links
            header="Get to Know Us"
            [links]="[
              { label: 'About Us', url: '/site/about-us' },
              { label: 'Privacy Policy', url: '/site/terms' },
              { label: 'Terms And Conditions', url: '/site/terms' },
              { label: 'Contact Us', url: '/site/contact-us' }
            ]"
          ></app-footer-links>
        </div>

        <div class="col-12 col-md-12 col-lg-4">
          <app-footer-newsletter
            class="site-footer__widget"
          ></app-footer-newsletter>
        </div>
      </div>
    </div>
    <div class="site-footer__bottom">
      <div class="site-footer__copyright">
        Copyright {{ date | date: "yyyy" }} © {{ app.name }}.
        <!-- <a
          class="developer-area"
          target="_blank"
          rel="noopener noreferrer"
          href="https://appslanka.lk/"
        >
          <img
            src="assets/images/logos/appslanka.png"
            alt="Appslanka"
            class="appslanka"
          />
          Developed by Apps Lanka Software Solutions
        </a> -->
      </div>
      <div class="site-footer__payments">
        <img *ngIf="app.stripe === 1" src="assets/images/payments.png" alt="" />
        <img
          *ngIf="app.payhere === 1"
          src="https://www.payhere.lk/downloads/images/payhere_long_banner.png"
          alt="PayHere"
          width="440"
        />
      </div>
    </div>
  </div>
  <app-totop></app-totop>
</div>
