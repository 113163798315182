import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';

import { AccountMenuComponent } from './components/account-menu/account-menu.component';
import { DropcartComponent } from './components/dropcart/dropcart.component';
import { HeaderComponent } from './header.component';
import { IndicatorComponent } from './components/indicator/indicator.component';
import { LinksComponent } from './components/links/links.component';
import { NavComponent } from './components/nav/nav.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        AccountMenuComponent,
        DropcartComponent,
        HeaderComponent,
        IndicatorComponent,
        LinksComponent,
        NavComponent,
        TopbarComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule, TranslateModule
    ],
    exports: [
        HeaderComponent,
        DropcartComponent,
        TranslateModule
    ]
})
export class HeaderModule { }
