<!-- .block-products -->
<div class="block block-products block-products--layout--{{ layout }}">
  <div class="container">
    <!-- <div *ngFor="let product of smalls" class="block-products__list-item">
      <app-product-card [product]="product"></app-product-card>
    </div> -->

    <div class="block-products__body">
      <ng-template #smallsTemplate>
        <div *ngIf="smalls.length > 0" class="block-products__list">
          <div *ngFor="let product of smalls" class="block-products__list-item">
            <app-product-card [product]="product"></app-product-card>
          </div>
        </div>
      </ng-template>

      <ng-container
        *ngIf="layout === 'large-first'"
        [ngTemplateOutlet]="smallsTemplate"
      ></ng-container>
    </div>
  </div>
</div>
<!-- .block-products / end -->
