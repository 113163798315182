import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class InterceptorsAuthServiceService implements HttpInterceptor {

  constructor(@Inject(PLATFORM_ID) private platformId: object) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let setHeaders: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    if (isPlatformBrowser(this.platformId)) {
      setHeaders = {
        ...setHeaders,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };
    }
    req = req.clone({ setHeaders });
    return next.handle(req);
  }
}
