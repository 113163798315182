import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable, from, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { User } from '../interfaces/user'
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from './application.service';
@Injectable({
    providedIn: 'root'
})
export class AccountService {
    user: User
    private onLoginSubject$: Subject<User> = new Subject();

    readonly onLogin$: Observable<User> = this.onLoginSubject$.asObservable();

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private app: ApplicationService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    loginOrRegister(credentials, endpoint: string) {
        let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/'
        return new Promise((resolve, reject) => {
            this.http.post(this.app.endpoint + endpoint, credentials)
                .subscribe(
                    res => {
                        if (isPlatformBrowser(this.platformId)) {
                            localStorage.setItem('token', res['token']);
                            this.onLoginSubject$.next(res['user']);
                            this.router.navigateByUrl(returnUrl)
                            resolve(res);
                        }
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }


    socialLogin(user: any) {
        console.log(user);
        const userData = {
            name: user.name,
            email: user.email,
            avatar: user.photoUrl,
            provider: (user.provider).toLowerCase(),
            provider_user_id: user.id,
        };
        return new Promise((resolve, reject) => {
            this.http.post(this.app.endpoint + 'social/login', userData)
                .subscribe(
                    res => {
                        if (isPlatformBrowser(this.platformId)) {
                            localStorage.setItem('token', res['token']);
                            this.onLoginSubject$.next(res['user']);
                            let returnUrl = localStorage.getItem('returnUrl');
                            if (!returnUrl) return;

                            localStorage.removeItem('returnUrl');
                            this.router.navigateByUrl(returnUrl);
                            resolve(res);
                        }
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    post(credentials, endpoint: string) {
        return new Promise((resolve, reject) => {
            this.http.post(this.app.endpoint + endpoint, credentials)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    postByUrl(credentials, endpoint: string) {
        return new Promise((resolve, reject) => {
            this.http.post(endpoint, credentials)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    delete(endpoint: string) {
        return new Promise((resolve, reject) => {
            this.http.delete(this.app.endpoint + endpoint)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    get(endpoint: string) {
        return this.http.get(this.app.endpoint + endpoint);
    }

    put(endpoint: string, body: any) {
        return new Promise((resolve, reject) => {
            this.http.put(this.app.endpoint + endpoint, body)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        console.log('Error occured 2: ', err);
                        reject(err);
                    }
                );
        });
    }

    isLocked() {
        if (isPlatformBrowser(this.platformId)) {
            if (window.localStorage.getItem('token')) {
                return true;
            } else {
                return false;
            }
        }
    }


    needLogin(): boolean {
        if (isPlatformBrowser(this.platformId)) {
            let needLogin = true;

            if (window.localStorage.getItem('token')) {
                needLogin = false;
            } else {
                return true;
            }
            return needLogin;
        }
    }

    getUser(): Observable<any> {
        return this.http.get<any>(this.app.endpoint + 'user');
    }

    getOrders(): Observable<any> {
        return this.http.get<any>(this.app.endpoint + 'orders');
    }

    postCart(cart) {
        return new Promise((resolve, reject) => {
            this.http.post(this.app.endpoint + 'carts', cart)
                .subscribe(
                    res => {

                        resolve(res);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }


    // getApplication() {
    //     return this.http.get(this.applicationsURL + location.hostname);
    // }
}
