<div class="footer-newsletter">
  <h5 class="footer-newsletter__title">Subscribe</h5>
  <div class="footer-newsletter__text">
    Promotions, new products and sales, Directly to your inbox.
  </div>

  <form action="" class="footer-newsletter__form">
    <label class="sr-only" for="footer-newsletter-address">Email Address</label>
    <input
      type="text"
      class="footer-newsletter__form-input form-control"
      [formControl]="emailFormControl"
      id="footer-newsletter-address"
      placeholder="Email Address..."
    />

    <button
      class="footer-newsletter__form-button btn btn-primary"
      (click)="emailFormControl.invalid ? '' : subscribe()"
      [disabled]="emailFormControl.invalid"
    >
      Subscribe
    </button>
  </form>

  <div class="footer-newsletter__text footer-newsletter__text--social">
    Say Hello On Social Media!
  </div>

  <app-social-links
    class="footer-newsletter__social-links"
    shape="circle"
  ></app-social-links>
</div>
