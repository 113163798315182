import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Shop } from '../interfaces/shop';
import { AccountService } from './account.service';
import { environment } from 'src/environments/environment';
import { PaymentMode } from '../interfaces/payment-mode';
@Injectable({
    providedIn: 'root'
})
export class ApplicationService {
    constructor() { }

    get name(): string { return environment.name; }
    get logo(): string { return environment.logo; }
    get icon(): string { return environment.icon; }
    get title(): string { return environment.title; }
    get primary_color(): string { return environment.primary_color; }
    get secondary_color(): string { return environment.secondary_color; }
    get accent_color(): string { return environment.accent_color; }
    get currency(): string { return environment.currency; }
    get endpoint(): string { return environment.endpoint; }
    get storage_url(): string { return environment.storage_url; }
    get locale(): string { return environment.locale }
    get homepage(): string { return environment.homepage; }
    get favorite(): number { return environment.favorite; }
    get deals(): number { return environment.deals; }
    get brand_filter(): number { return environment.brand_filter; }
    get reviews(): number { return environment.reviews }
    get payhere(): number { return environment.payhere }
    get stripe(): number { return environment.stripe; }
    get cod(): number { return environment.cod; }
    get stripe_key(): string { return environment.stripe_key; }
    get sandbox_payhere(): boolean { return environment.sandbox_payhere; }
    get merchant_id_payhere(): string { return environment.merchant_id_payhere; }
    get return_url_payhere(): string { return environment.return_url_payhere; }
    get notify_url_payhere(): string { return environment.notify_url_payhere; }
    get cancel_url_payhere(): string { return environment.cancel_url_payhere; }
    get currency_payhere(): string { return environment.currency_payhere; }
    get default_payment_method(): any { return environment.default_payment_method; }
    get push(): number { return environment.push; }
    get google_provider(): string { return environment.google_login_provider_id; }
    get facebook_app_id(): string { return environment.facebook_app_id; }
    get facebook_page_id(): string { return environment.facebook_page_id; }
    get apple_app_id(): string { return environment.apple_app_id; }
    get logoBaseUrl(): string { return 'https://ecommerce-admin.appslanka.lk/storage/' }
}
