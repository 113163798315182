<!-- <nav
    class="navbar navbar-dark site-header__top sticky-top flex-md-nowrap bg-white p-0 shadow-nav"
>
    <div class="site-header">
        <div class="container">
            <div class="site-header__logo">
                <a routerLink="/">
                    Ecommerce Demo
                </a>
            </div>
            <div class="site-header__search">
                <app-search location="header"></app-search>
            </div>
        </div>
    </div>
</nav> -->

<div class="site-header">
  <app-header-topbar></app-header-topbar>
  <div class="site-header__nav-panel">
    <app-header-nav
      [departments]="layout === 'classic'"
      [logo]="layout === 'compact'"
      [search]="layout === 'compact'"
      stickyMode="alwaysOnTop"
    ></app-header-nav>
  </div>
</div>
