<div class="search__body">
  <form [formGroup]="form" class="search__form">
    <select
      *ngIf="location === 'header'"
      class="search__categories"
      aria-label="Category"
      formControlName="category"
      (focus)="closeSuggestion()"
    >
      <option value="all">{{ "ALL_CATEGORIES" | translate }}</option>
      <option
        *ngFor="let category of categories"
        [value]="category.id"
        [innerHTML]="getCategoryName(category)"
      ></option>
    </select>
    <input
      class="search__input"
      name="search"
      placeholder="{{ 'SEARCH_FOR_PRODUCTS' | translate }}"
      aria-label="Site search"
      type="text"
      autocomplete="off"
      formControlName="query"
      (keydown.escape)="escape.next()"
      (focus)="openSuggestion()"
      #input
    />
    <button class="search__button search__button--type--submit" type="submit">
      <app-icon name="search-20" size="20"></app-icon>
    </button>
    <button
      *ngIf="location === 'mobile-header'"
      class="search__button search__button--type--close"
      type="button"
      (click)="closeButtonClick.next()"
    >
      <app-icon name="cross-20" size="20"></app-icon>
    </button>
    <div class="search__border"></div>
  </form>

  <div
    class="search__suggestions suggestions suggestions--location--{{
      location
    }}"
  >
    <ul class="suggestions__list">
      <div
        class="no_result_text_wrap"
        *ngIf="hasSuggestions === false && noSuggestions === true"
      >
        <h2 class="no_result_text_h2">
          {{ "COULD_NOT_FIND" | translate }}
          <span class="no_result_text_span"
            >"{{ form.get("query").value }}"</span
          >
        </h2>
        <ul class="no_result_text_list">
          <li>
            <h3 class="no_result_text_list_h3">
              {{ "TRY_DIFF_CATE" | translate }}
            </h3>
            <p class="text_overflow no_result_text_list_p">
              {{ "TRY_DIFF_CATE_DESC" | translate }}
            </p>
          </li>
          <li>
            <h3 class="no_result_text_list_h3">
              {{ "TRY_DIFF_WORDS" | translate }}
            </h3>
            <p class="text_overflow no_result_text_list_p">
              {{ "TRY_DIFF_WORDS_DESC" | translate }}
            </p>
          </li>
          <li>
            <h3 class="no_result_text_list_h3">
              {{ "CHK_SPELL" | translate }}
            </h3>
            <p class="text_overflow no_result_text_list_p">
              {{ "CHK_SPELL_DESC" | translate }}
            </p>
          </li>
          <li>
            <h3 class="no_result_text_list_h3">
              {{ "EXACT_PHRASE" | translate }}
            </h3>
            <p class="text_overflow no_result_text_list_p">
              {{ "EXACT_PHRASE_DESC" | translate }}
            </p>
          </li>
        </ul>
      </div>
      <ng-container *ngFor="let products of suggestedProducts">
        <li class="suggestions__item" *ngFor="let product of products.variants">
          <div class="suggestions__item-image product-image">
            <div class="product-image__body">
              <img
                *ngIf="
                  product.images != null && this.product.images?.length > 0
                "
                class="product-image__img"
                src="{{ storage_URL }}{{ product.images[0] }}"
                alt=""
              />

              <img
                *ngIf="
                  product.images === null || this.product.images?.length < 1
                "
                class="product-image__img"
                src="/assets/images/logos/no-image.png"
                alt=""
              />
            </div>
          </div>

          <div class="suggestions__item-info">
            <a
              [routerLink]="root.product(product.product)"
              class="suggestions__item-name"
              (click)="closeSuggestion()"
            >
              {{ product.title }}
            </a>
            <div class="suggestions__item-meta">SKU: {{ product.sku }}</div>
          </div>
          <div class="suggestions__item-price">
            {{ product.selling_price | currencyFormat }}
          </div>

          <div *ngIf="location === 'header'" class="suggestions__item-actions">
            <button
              type="button"
              title="Add to cart"
              class="btn btn-primary btn-sm btn-svg-icon"
              [class.btn-loading]="addedToCartProducts.includes(product)"
              (click)="addToCart(product)"
            >
              <app-icon name="cart-16" size="16"></app-icon>
            </button>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
