import { Component, OnDestroy, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Brand } from '../../shared/interfaces/brand';
import { Observable, Subject, merge } from 'rxjs';
import { ShopService } from '../../shared/api/shop.service';
import { Product } from '../../shared/interfaces/product';
import { Category } from '../../shared/interfaces/category';
import { BlockHeaderGroup } from '../../shared/interfaces/block-header-group';
import { takeUntil, tap } from 'rxjs/operators';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { RootService } from 'src/app/shared/services/root.service';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';

interface ProductsCarouselGroup extends BlockHeaderGroup {
    products$: Observable<Product[]>;
}

interface ProductsCarouselData {
    abort$: Subject<void>;
    loading: boolean;
    products: Product[];
    groups: ProductsCarouselGroup[];
}

@Component({
    selector: 'app-home',
    templateUrl: './page-home.component.html',
    styleUrls: ['./page-home.component.scss']
})
export class PageHomeComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    products: Product[] = [];
    brands: Brand[] = [];
    loading: boolean = false
    deals: any[] = []
    categories: Category[];
    layout: 'standard' | 'columnar' | 'sidebar' = 'standard';
    viewMode: 'grid' | 'grid-with-features' | 'list' = 'grid';
    columns: 3 | 4 | 5 = 5;

    next_page_url: any = null

    throttle = 150;
    scrollDistance = 1;
    slides: any = null;
    constructor(
        private shop: ShopService,
        public setting: SettingsService,
        public app: ApplicationService,
        private gtag: GoogleAnalyticsService,
        @Inject(PLATFORM_ID) private platformId: object,

    ) { }

    async ngOnInit(): Promise<void> {
        this.loading = true
        this.shop.getProductsList("", { limit: 16 }).then(res => {
            this.products = res['data']
            this.next_page_url = res['next_page_url']
            this.shop.getSliders('1').subscribe(res => this.slides = res[0])
            this.loading = false
        });
        this.shop.getDeals().subscribe(res => this.deals = res)
        this.shop.getBrands().subscribe(res => this.brands = res)
        if (isPlatformBrowser(this.platformId)) {
            this.gtag.sendEvent('home page', 'page');
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    // filterCategory(ev: any) {
    //     this.router.navigateByUrl(this.root.category(ev)).then();
    // }
    // filterByBrand(item) {
    //     this.router.navigateByUrl(this.root.brand(item)).then();
    // }
    onScrollDown() {
        if (this.next_page_url != null) {
            if (isPlatformBrowser(this.platformId)) {
                this.shop.getProductsByUrl(this.next_page_url).subscribe(res => {
                    this.next_page_url = res['next_page_url']
                    this.products = [...this.products, ...res['data']];
                });
            }
        }
    }
}
