import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DirectionService } from '../../../shared/services/direction.service';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { ShopService } from 'src/app/shared/api/shop.service';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss']
})
export class BlockSlideshowComponent implements OnInit {
    @Input() withDepartments = false;
    @Input() slides: any;

    storage_URL = this.app.storage_url

    options = {
        nav: false,
        dots: true,
        loop: true,
        autoplay: true,
        center: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: { items: 1 }
        },
    };
    constructor(
        public sanitizer: DomSanitizer,
        private app: ApplicationService,
    ) { }
    ngOnInit(): void {
    }

}
