import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ShopService } from '../api/shop.service';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private data: any
    get androidLink(): string { return this.data?.filter(config => config.name == 'android_link').length ? this.data?.filter(config => config.name == 'android_link')[0]['value'] : null; }
    get iOSLink(): string { return this.data?.filter(config => config.name == 'ios_link').length ? this.data?.filter(config => config.name == 'ios_link')[0]['value'] : null; }
    get address(): string { return '23 Pultney Street, VIC - 3175.' }
    get email(): string { return 'info@mks.net.au' }
    get mobile(): string { return '+61 3 9701 3165' }
    get instagram(): string { return this.data?.filter(config => config.name == 'instagram').length ? this.data?.filter(config => config.name == 'instagram')[0]['value'] : null; }
    get facebook(): string { return this.data?.filter(config => config.name == 'facebook').length ? this.data?.filter(config => config.name == 'facebook')[0]['value'] : null; }
    get whatsapp(): string { return this.data?.filter(config => config.name == 'whatsapp').length ? this.data?.filter(config => config.name == 'whatsapp')[0]['value'] : null; }
    get contactNote(): string { return 'We are ready to serve you on your door step, If you have more question please contact us.' }
    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private shop: ShopService,
    ) {
        // if (isPlatformBrowser(this.platformId)) {
        //     this.load();
        // }
    }

    // async load(): Promise<void> {
    //     this.shop.getSettings().subscribe(res => {
    //         this.data = res
    //     });
    // }
}
