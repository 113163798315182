import { Component } from '@angular/core';
import { PostService } from 'src/app/shared/services/post.service';

@Component({
    selector: 'app-terms',
    templateUrl: './page-terms.component.html',
    styleUrls: ['./page-terms.component.scss']
})
export class PageTermsComponent {
    constructor(public post: PostService) {
    }
}
