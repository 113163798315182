import { Component, OnInit, Inject, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ViewportScroller, isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from './shared/api/account.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ApplicationService } from './shared/api/application.service';
import { DOCUMENT } from '@angular/common';
import { CurrencyService } from './shared/services/currency.service';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild("ageVerification") AgeVerificationModal: TemplateRef<any>;
  public closeResult: string;

  socialLogin: boolean = false;
  constructor(
    private router: Router,
    private scroller: ViewportScroller,
    private titleService: Title,
    private account: AccountService,
    private app: ApplicationService,
    private translate: TranslateService,
    private currency: CurrencyService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    @Inject(PLATFORM_ID) private platformId: object) {
    // auth.user$.subscribe(user => {
    //     if (!user) return;

    //     console.log(user);

    //     let returnUrl = localStorage.getItem('returnUrl');
    //     if (!returnUrl) return;

    //     localStorage.removeItem('returnUrl');
    //     router.navigateByUrl(returnUrl);
    // });
  }

  // ngAfterViewInit(): void {
  //   this.openModal();
  // }




  ngOnInit(): void {
    this.currency.options = {
      code: this.app.currency + ' ',
      // display: 'symbol',
      // digitsInfo: '1.2-2',
      // locale: 'en-US'
    };
    document.documentElement.style.setProperty('--primary-color', this.app.primary_color);
    document.documentElement.style.setProperty('--secondary-color', this.app.secondary_color);
    document.documentElement.style.setProperty('--accent-color', this.app.primary_color);
    this.titleService.setTitle(this.app.title);
    this.router.events.subscribe((event) => {
      if ((event instanceof NavigationEnd)) {
        this.scroller.scrollToPosition([0, 0]);
      }
    });
    if (this.account.isLocked() === true) {
      this.account.getUser().subscribe(res => {
        this.account.user = res;
      });
    }

    // TODO
    if (isPlatformBrowser(this.platformId)) {
      const browserLang = window.localStorage.getItem('lang');
      if (browserLang) {
        this.translate.setDefaultLang(browserLang);
        this.translate.use(browserLang);
      }
      else if (this.app.locale != 'en') {
        this.translate.setDefaultLang(this.app.locale);
        this.translate.use(this.app.locale);
      }

      let gaScript = document.createElement('script');
      gaScript.setAttribute('async', 'true');
      gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${environment.gtag}`);

      let gaScript2 = document.createElement('script');
      gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${environment.gtag}\');`;

      document.documentElement.firstChild.appendChild(gaScript);
      document.documentElement.firstChild.appendChild(gaScript2);
    }
  }


}
