import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Category } from '../interfaces/category';
import { HttpClient } from '@angular/common/http';
import { Brand } from '../interfaces/brand';
import { Product } from '../interfaces/product';
import { Deal } from '../interfaces/deal';

import { ProductsList } from '../interfaces/list';
import { SerializedFilterValues } from '../interfaces/filter';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { map } from 'rxjs/operators';
import { ApplicationService } from './application.service'
import { Slider } from '../interfaces/slider';
import GraphQLJSClient from 'graphql-js-client';
import typeBundle from '../types';

export interface ListOptions {
    page?: number;
    limit?: number;
    sort?: string;
    brand?: string;
    category?: string;
    sub_category?: string;
    next_page_url?: string;
    filterValues?: SerializedFilterValues;
}

@Injectable({
    providedIn: 'root'
})
export class ShopService {

    client = new GraphQLJSClient(typeBundle, {
        url: environment.url,
        fetcherOptions: {
            headers: {
                'X-Shopify-Storefront-Access-Token': environment.shopifyaccesstoken
            }
        }
    });

    constructor(
        private http: HttpClient,
        private app: ApplicationService,
        @Inject(PLATFORM_ID) private platformId: object,
    ) { }

    /**
     * Returns category object by slug.
     *
     * @param slug - Unique human-readable category identifier.
     */
    getCategory(slug: string): Observable<Category> {
        const params: { [param: string]: string } = {};
        //
        if (slug) {
            params.id = slug;
        }
        // this.apiService.get("products?category=" + this.filterService.category.id + "&brand=" + this.filterService.brand.id + "&sub_category=" + this.filterService.sub_category.id).subscribe(res => {
        return this.http.get<Category>(this.app.endpoint + 'categories', { params });

        return null;
    }

    /**
     * Returns a category tree.
     *
     * @param parent - If a parent is specified then its descendants will be returned.
     * @param depth  - Maximum depth of category tree.
     */
    getCategories(parent: Partial<Category> = null, depth: number = 0): Observable<Category[]> {
        return this.http.get<any[]>(this.app.endpoint + 'categories');
    }
    /**
     * Returns paginated products list.
     *
     * @param categorySlug         - Unique human-readable category identifier.
     * @param options              - Options.
     * @param options.page         - Page number (optional).
     * @param options.limit        - Maximum number of items returned at one time (optional).
     * @param options.sort         - The algorithm by which the list should be sorted (optional).
     * @param options.filterValues - An object whose keys are filter slugs and values ​​are filter values (optional).
     */
    getProductsList(categorySlug: string | null, options: ListOptions): Promise<ProductsList> {
        const params: { [param: string]: string } = {};
        //
        if (categorySlug) {
            params.categorySlug = categorySlug;
        }
        if (options != null) {
            if ('page' in options) {
                params.page = options.page.toString();
            }
            if ('limit' in options) {
                params.limit = options.limit.toString();
            }
            if ('sort' in options) {
                params.sort = options.sort;
            }
        }

        const titleEnum = this.client.enum("CREATED_AT");
        let query = this.client.query((root) => {
            root.add('shop', (shop) => {
                shop.addConnection('products', { args: { first: 28, query: "available_for_sale:true", sortKey: titleEnum, reverse: true } }, (products) => {
                    products.add('id');
                    products.add('title');
                    // products.add('totalInventory');
                    products.add('tags');
                    products.addConnection('images', { args: { first: 28 } }, (images) => {
                        images.add('src');
                        images.add('id');
                        images.add('altText');
                    })
                })
            })
        });
        return this.client.send(query)
            .then(({ model, data }) => {
                console.log(model);
                let res: { model?: any, data?: any } = {};
                res.data = data;
                res.model = model
                return res
            });
    }

    getProductById(_id): Promise<Product> {
        let client = this.client;

        let query = client.query((root) => {
            root.add('node', { args: { id: _id }, alias: 'product' }, (node) => {
                node.add('id');
                node.addInlineFragmentOn('Product', (Product) => {
                    Product.add('title');
                    Product.add('createdAt');
                    Product.add('description');
                    Product.add('descriptionHtml');
                    Product.add('productType');
                    Product.add('publishedAt');
                    Product.add('tags');
                    Product.add('updatedAt');
                    Product.add('vendor');
                    Product.addConnection('images', { args: { first: 25 } }, (images) => {
                        images.add('src');
                        images.add('id');
                        images.add('altText');
                    })
                    Product.addConnection('variants', { args: { first: 25 } }, (variants) => {
                        variants.add('id');
                        variants.add('available');
                        variants.add('weightUnit');
                        variants.add('sku');
                        variants.add('product', (prod) => {
                            prod.add('title');
                            prod.addConnection('images', { args: { first: 25 } }, (images) => {
                                images.add('src');
                                images.add('id');
                                images.add('altText');
                            })
                        })
                        variants.add('title');
                        variants.add('price');
                        variants.add('image', (image) => {
                            image.add('src');
                            image.add('id');
                            image.add('altText');
                        })
                    })
                })
            })
        });

        return client.send(query).then(({ model, data }) => {
            return client.fetchAllPages(model.product, { pageSize: 250 })
        });

    }
    /**
 * Returns paginated products list.
 * If categorySlug is null then a list of all products should be returned.
 *
 * @param categorySlug         - Unique human-readable category identifier.
 * @param options              - Options.
 * @param options.page         - Page number (optional).
 * @param options.limit        - Maximum number of items returned at one time (optional).
 * @param options.sort         - The algorithm by which the list should be sorted (optional).
 * @param options.filterValues - An object whose keys are filter slugs and values ​​are filter values (optional).
 */
    getFilteredProductsList(categorySlug: string | null, options: ListOptions): Observable<ProductsList> {

        const params: { [param: string]: string } = {};
        if (categorySlug) {
            params.category = categorySlug;
        }
        // console.log(options);
        // if (params.category) {
        //     options.category = parseFloat(params.category);
        // }
        // if (params.brand) {
        //     options.brand = parseFloat(params.brand);
        // }
        // if (params.sub_category) {
        //     options.sub_category = parseFloat(params.sub_category);
        // }
        if ('brand' in options) {
            params.brand = options.brand.toString();
        }
        if ('sub_category' in options) {
            params.sub_category = options.sub_category.toString();
        }
        if ('category' in options) {
            params.category = options.category.toString();
        }
        if ('page' in options) {
            params.page = options.page.toString();
        }
        // if ('limit' in options) {
        //     params.limit = options.limit.toString();
        // }
        if ('sort' in options) {
            params.sort = options.sort;
        }
        if ('filterValues' in options) {
            Object.keys(options.filterValues).forEach(slug => params[`${slug}`] = options.filterValues[slug]);
        }
        return this.http.get<ProductsList>(this.app.endpoint + 'products', { params });
    }

    getProductsListById(id: any): Observable<ProductsList> {
        return this.http.get<ProductsList>(this.app.endpoint + 'products?category=' + id);
    }

    getProduct(productSlug: string): Observable<Product> {
        const params: { [param: string]: string } = {};
        params.slug = productSlug;
        return this.http.get<Product>(this.app.endpoint + 'products', { params });
    }

    /**
     * Returns  brands.
     */
    getBrands(): Observable<Brand[]> {

        return this.http.get<any[]>(this.app.endpoint + 'brands');
    }

    getCategoryList(): Observable<Category[]> {
        return this.http.get<any[]>(this.app.endpoint + 'categories');
    }

    getProducts(): Observable<Product[]> {

        return this.http.get<any[]>(this.app.endpoint + 'products');
    }

    getRelatedProducts(categoryId: string): Observable<Product[]> {

        const params: { [param: string]: string } = {};
        params.category = categoryId;
        return this.http.get<any>(this.app.endpoint + 'products', { params });
    }

    getProductsByUrl(url: string): Observable<Product[]> {
        return this.http.get<any[]>(url);
    }

    getSuggestions(query: string, limit: number, categorySlug: string = null): Observable<Product[]> {
        const params: { [param: string]: string } = {};

        if (categorySlug) {
            params.category_id = categorySlug;
        }
        return this.http.get<any[]>(this.app.endpoint + 'products?title=' + query, { params });;
    }

    /**
 * Returns paginated Order list.
 *
 * @param categorySlug         - Unique human-readable category identifier.
 * @param options              - Options.
 * @param options.page         - Page number (optional).
 * @param options.limit        - Maximum number of items returned at one time (optional).
 * @param options.sort         - The algorithm by which the list should be sorted (optional).
 * @param options.filterValues - An object whose keys are filter slugs and values ​​are filter values (optional).
 */
    getOrderList(options: ListOptions): Observable<ProductsList> {
        const params: { [param: string]: string } = {};
        if (options != null) {
            if ('page' in options) {
                params.page = options.page.toString();
            }
            if ('limit' in options) {
                params.limit = options.limit.toString();
            }
            if ('sort' in options) {
                params.sort = options.sort;
            }
        }
        return this.http.get<ProductsList>(this.app.endpoint + 'orders', { params });
    }


    getOrder(orderID: string): Observable<any> {
        const params: { [param: string]: string } = {};
        if (orderID != undefined)
            params.id = orderID;
        return this.http.get<Product>(this.app.endpoint + 'orders', { params });
    }


    getLineItems(orderID: string): Observable<any> {
        const params: { [param: string]: string } = {};
        params.order_id = orderID;
        return this.http.get<Product>(this.app.endpoint + 'line_items', { params });
    }

    //Cart
    getVariant(cartId: string, variant_id: string): Observable<any> {
        return this.http.get<Product>(this.app.endpoint + 'carts?cart=' + cartId + '&variant_id=' + variant_id)
    }

    getLang() {
        if (isPlatformBrowser(this.platformId)) {
            return window.localStorage.getItem('lang') || this.app.locale;
        }
    }
    //SLIDERS
    getSliders(id: any): Observable<Slider> {
        return this.http.get<Slider>(this.app.endpoint + 'sliders?slider_type=' + id);
    }

    getDeals(): Observable<any> {
        return this.http.get<Deal>(this.app.endpoint + 'deals');
    }

    getDeal(id: string): Observable<Deal> {
        const params: { [param: string]: string } = {};
        params.id = id;
        return this.http.get<Deal>(this.app.endpoint + 'deals', { params });
    }

    getSettings(): Observable<any> {
        return this.http.get<any>(this.app.endpoint + 'settings');
    }

    getPosts(): Observable<any> {
        return this.http.get<any>(this.app.endpoint + 'posts');
    }

    getPaymentStatus(order_id: string): Observable<any> {
        const params: { [param: string]: string } = {};
        params.order_id = order_id;
        return this.http.get<any>(this.app.endpoint + 'payments', { params });
    }
}
