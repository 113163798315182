import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RootComponent } from './components/root/root.component';
import { PageHomeAdsComponent } from './pages/page-home-ads/page-home-ads.component';

const routes: Routes = [
  {
    path: '',
    component: RootComponent,
    data: {
      headerLayout: 'classic',
      dropcartType: 'dropdown'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        // component: PageImgPageComponent
        component: PageHomeAdsComponent,
      },
      // {
      //   path: 'adhome',
      //   pathMatch: 'full',
      //   component: PageHomeAdsComponent
      // },
      {
        path: 'site',
        loadChildren: () => import('./modules/site/site.module').then(m => m.SiteModule)
      },
      {
        path: '**',
        component: PageNotFoundComponent
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
