import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ShopService } from '../api/shop.service';

@Injectable({
    providedIn: 'root'
})
export class PostService {
    private data: any
    get terms(): any { return this.data?.filter(post => post.slug == 'terms').length ? this.data?.filter(post => post.slug == 'terms')[0] : null; }
    get refundPolicy(): any { return this.data?.filter(post => post.slug == 'refund-policy').length ? this.data?.filter(post => post.slug == 'refund-policy')[0] : null; }
    get privacyPolicy(): any { return this.data?.filter(post => post.slug == 'privacy-policy').length ? this.data?.filter(post => post.slug == 'privacy-policy')[0] : null; }
    get aboutUs(): any { return this.data?.filter(post => post.slug == 'about-us').length ? this.data?.filter(post => post.slug == 'about-us')[0] : null; }
    get aboutUsImg(): any { return this.data?.filter(post => post.slug == 'about-us').length ? this.data?.filter(post => post.slug == 'about-us')[0]['image'] : null; }

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private shop: ShopService,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.load();
        }
    }

    async load(): Promise<void> {
        this.shop.getPosts().subscribe(res => this.data = res['data']);
    }
}
