<app-page-header
  [breadcrumbs]="[
    { label: 'Home', url: '../../' },
    { label: 'Terms And Conditions', url: '' }
  ]"
></app-page-header>

<div class="block" *ngIf="post.terms != null">
  <div class="container">
    <div class="document">
      <div class="document__header">
        <h1 class="document__title">{{ post.terms["title"] }}</h1>
        <div class="document__subtitle">Last modified on 12 Aug 2020.</div>
      </div>
      <div
        class="document__content typography"
        [innerHTML]="post.terms['content']"
      ></div>
    </div>
  </div>
</div>
