import { Component, Input } from '@angular/core';
import { Category } from '../../../shared/interfaces/category';
import { RootService } from '../../../shared/services/root.service';
import { environment } from 'src/environments/environment';
import { ApplicationService } from 'src/app/shared/api/application.service';

@Component({
    selector: 'app-block-categories',
    templateUrl: './block-categories.component.html',
    styleUrls: ['./block-categories.component.scss']
})
export class BlockCategoriesComponent {
    storage_URL = this.app.storage_url

    @Input() header = '';
    @Input() layout: 'classic' | 'compact' = 'classic';
    @Input() categories: Category[] = [];

    constructor(
        public root: RootService,
        private app: ApplicationService
    ) { }
}
