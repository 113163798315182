import { Component } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AccountService } from 'src/app/shared/api/account.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-contact-us',
    templateUrl: './page-contact-us.component.html',
    styleUrls: ['./page-contact-us.component.scss']
})
export class PageContactUsComponent {
    contactForm: FormGroup;
    sending: boolean = false

    constructor(private fb: FormBuilder, private account: AccountService,
        private toastr: ToastrService,
        private translate: TranslateService
    ) {
        this.contactForm = this.fb.group({
            email: ['', [Validators.email]],
            name: ['', [Validators.required, Validators.minLength(2)]],
            subject: ['', [Validators.required]],
            phone: [''],
            message: ['', [Validators.required, Validators.minLength(3)]],
            // acceptsMarketing: [false]
        });
    }

    async sendMessage() {
        this.sending = true
        this.account.post(this.contactForm.value, 'contacts').then(async (result) => {
            this.translate.get(['MSG_SENT']).subscribe(async translate => {
                this.toastr.success(translate.MSG_SENT);
            })
            this.contactForm.reset()
            this.sending = false
        },
            async (err) => {
                this.translate.get(['TRY_AGAIN']).subscribe(async translate => {
                    this.toastr.error(translate.TRY_AGAIN);
                })
                this.sending = false
            });
    }
}
