<ul class="social-links__list">
  <li class="social-links__item">
    <a
      class="social-links__link social-links__link--type--facebook"
      target="_blank"
      href="https://www.facebook.com/MksSpicesAndThings/"
    >
      <i class="fab fa-facebook-f"></i>
    </a>
  </li>
  <li class="social-links__item">
    <a
      class="social-links__link social-links__link--type--facebook"
      target="_blank"
      href="https://www.facebook.com/mkstalkies/"
    >
      <i class="fab fa-facebook-f"></i>
    </a>
  </li>

  <li class="social-links__item">
    <a
      class="social-links__link social-links__link--type--twitter"
      target="_blank"
      href="https://twitter.com/mksspices"
    >
      <i class="fab fa-twitter"></i>
    </a>
  </li>
</ul>
