import { Component, OnDestroy, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Brand } from '../../shared/interfaces/brand';
import { Observable, Subject, merge } from 'rxjs';
import { ShopService } from '../../shared/api/shop.service';
import { Product } from '../../shared/interfaces/product';
import { Category } from '../../shared/interfaces/category';
import { BlockHeaderGroup } from '../../shared/interfaces/block-header-group';
import { takeUntil, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { RootService } from 'src/app/shared/services/root.service';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { AccountService } from 'src/app/shared/api/account.service';
import { Slider } from 'src/app/shared/interfaces/slider';


interface ProductsCarouselGroup extends BlockHeaderGroup {
  products$: Observable<Product[]>;
}

interface ProductsCarouselData {
  abort$: Subject<void>;
  loading: boolean;
  products: Product[];
  groups: ProductsCarouselGroup[];
}

@Component({
  selector: 'app-page-home-ads',
  templateUrl: './page-home-ads.component.html',
  styleUrls: ['./page-home-ads.component.scss']
})
export class PageHomeAdsComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  categories: Category[] = [];
  layout: 'standard' | 'columnar' | 'sidebar' = 'standard';
  loading: boolean = false;
  ads: any = [];
  brands: Brand[] = [];
  mobileAds: any = [];
  slides: any
  services = [
    {
      title: 'Dandenong',
      subtitle: "23 Pultney Street, VIC - 3175.",
      image: 'assets/images/dandenong.jpeg',
      mobile: '+61 3 9701 3165'
    },
    {
      title: 'Ashwood',
      subtitle: "382 Warrigal Road, VIC - 3147.",
      image: 'assets/images/ashwood.jpeg',
      mobile: '+61 3 9885 9932'
    },
    {
      title: 'Preston',
      subtitle: "258 High Street, VIC - 3072.",
      image: 'assets/images/preston.jpeg',
      mobile: '+61 3 9416 9011'
    },
    {
      title: 'Epping',
      subtitle: "1/88 Cooper Street, VIC - 3076.",
      image: 'assets/images/epping.jpeg',
      mobile: '+61 3 9424 9162'
    },
    {
      title: 'St Albans',
      subtitle: "276 Main Road East, VIC - 3021.",
      image: 'assets/images/st_albans.jpeg',
      mobile: '+61 3 9366 4141'
    },

  ];
  constructor(
    private router: Router,
    private root: RootService,
    public setting: SettingsService,
    private account: AccountService,
    private shop: ShopService,
    @Inject(PLATFORM_ID) private platformId: object,

  ) { }

  ngOnInit(): void {
    this.loading = true
    this.shop.getSliders('1').subscribe(res => this.slides = res[0])
    this.shop.getSliders('2').subscribe(res => this.mobileAds = res)
    this.shop.getSliders('3').subscribe(res => this.ads = res)
    this.shop.getCategoryList().subscribe(res => this.categories = res['data'])
    this.shop.getBrands().subscribe(res => this.brands = res)
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  filterCategory(ev: any) {
    this.router.navigateByUrl(this.root.category(ev)).then();
  }
}
