<!-- .block-features -->

<div class="block block-features block-features--layout--{{ layout }}">
  <div class="container">
    <div class="block-features__list">
      <div class="block-features__item">
        <div class="block-features__icon">
          <app-icon name="cart-20" size="48"></app-icon>
        </div>
        <div class="block-features__content">
          <div class="block-features__title">Place order</div>
          <div class="block-features__subtitle">Order from biggest variety</div>
        </div>
      </div>
      <div class="block-features__divider"></div>
      <div class="block-features__item">
        <div class="block-features__icon">
          <app-icon name="fi-payment-security-48" size="48"></app-icon>
        </div>
        <div class="block-features__content">
          <div class="block-features__title">Secure payment</div>
          <div class="block-features__subtitle">Only secure payments</div>
        </div>
      </div>
      <div class="block-features__divider"></div>
      <div class="block-features__item">
        <div class="block-features__icon">
          <app-icon name="fi-free-delivery-48" size="48"></app-icon>
        </div>
        <div class="block-features__content">
          <div class="block-features__title">No‑contact delivery</div>
          <div class="block-features__subtitle">
            We are working hard to ensure your safty!
          </div>
        </div>
      </div>
      <div class="block-features__divider"></div>
      <div class="block-features__item">
        <div class="block-features__icon">
          <app-icon name="heart-20" size="48"></app-icon>
        </div>
        <div class="block-features__content">
          <div class="block-features__title">Have your order delivered</div>
          <div class="block-features__subtitle">
            To your door at a time convenient for you
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- .block-features / end -->
