<app-block-slideshow [slides]="slides" *ngIf="slides"></app-block-slideshow>
<app-block-features layout="boxed"></app-block-features>

<section id="services" class="services-area ptb-38">
  <div class="container">
    <div class="section-title">
      <h2>Our <span>Branches</span></h2>
      <p>
        Retailers of BEST quality Indian, Sri Lankan, Pakistani, Fijian, etc
        grocery products with large range of Rice, Pickles, Spices, Beans &
        fresh Vegetables.
      </p>
    </div>

    <!-- <div class="team-slides"> -->
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of services">
        <div class="single-team-box">
          <div class="image">
            <img src="{{ item.image }}" alt="img" />
          </div>

          <div class="content">
            <h3 class="title">{{ item.title }}</h3>
            <span class="post">{{ item.subtitle }}</span>
          </div>

          <div class="social">
            <ul>
              <li>
                <a
                  href="tel:{{ item.mobile }}"
                  class="fas fa-phone-alt tel-color"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>

  <div class="shape1"><img src="assets/images/shape1.png" alt="image" /></div>
</section>
<app-block-banner [ad]="ads" [mobileAd]="mobileAds"> </app-block-banner>
<!-- <app-block-brands
  *ngIf="brands.length > 0"
  [brands]="brands"
></app-block-brands> -->
<app-footer></app-footer>
