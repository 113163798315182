// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: "MKS Spices'n Things",
  endpoint: "https://admin.mymks.com.au/api/",
  icon: "https://admin.mymks.com.au/app_icon.png",
  logo: "https://admin.mymks.com.au/logo.png",
  title: "MKS Spices'n Things",
  primary_color: "#3CA1F2",
  secondary_color: "#68C0D3",
  accent_color: '#3CA1F2',
  currency: "€",
  storage_url: "https://admin.mymks.com.au/storage/",
  google_login_provider_id: "193707041852-n0fn4q2dte4la2l4h9g4pho50l5aka0c.apps.googleusercontent.com",
  facebook_app_id: "1242800786057247",
  apple_app_id: "ecommerce-angular.appslanka.lk",
  redirectURI: 'http://nexotique.fr',
  stripe_key: 'pk_test_37qYnVTwfhHEqHBGrcIaHPG3',
  notify_url_payhere: 'https://admin.mymks.com.au/api/payhere/checkout',
  return_url_payhere: 'http://admin.mymks.com.au/',
  cancel_url_payhere: 'http://admin.mymks.com.au/',
  merchant_id_payhere: '1211147',
  sandbox_payhere: true,
  currency_payhere: 'LKR',
  facebook_page_id: '104533561378747',
  default_payment_method: '3',
  // 1 => COD, 2 => Stripe, 3=>Payhere  
  gtag: 'UA-156957676-3',
  locale: 'en',
  homepage: 'default',
  favorite: 1,
  deals: 1,
  reviews: 1,
  payhere: 0,
  stripe: 1,
  cod: 1,
  push: 0,
  brand_filter: 1,
  logoBaseUrl: 'https://admin.nexotique.fr/storage/',
  url: 'https://mks-dandenong.myshopify.com/api/graphql',
  shopifyaccesstoken: 'fc317fef71f65e5bd534f99448ba91e3'
};

/*
production: true,
  name: "Y-Gold",
  title: "Y-Gold | Jewelry Store In YourTown",
  primary_color: "#28a745",
  secondary_color: "#28a745",
  accent_color: '#28a745',
  currency: "Rs",
  endpoint: "https://admin.ygold.lk/api/",
  storage_url: "https://admin.ygold.lk/storage/",
  logoBaseUrl: 'https://ecommerce-admin.appslanka.lk/storage/',
  icon: "https://admin.ygold.lk/storage/app_icon.png",
  logo: "https://admin.ygold.lk/storage/logo.jpeg",
  google_login_provider_id: "193707041852-n0fn4q2dte4la2l4h9g4pho50l5aka0c.apps.googleusercontent.com",
  facebook_app_id: "735922976955808",
  apple_app_id: "login.ygold.lk",
  redirectURI: 'https://ygold.lk',
  stripe_key: 'pk_test_37qYnVTwfhHEqHBGrcIaHPG3',
  notify_url_payhere: 'https://ecommerce-admin.appslanka.lk/api/payment/payhere/checkout',
  return_url_payhere: 'http://ygold.lk/',
  cancel_url_payhere: 'http://ygold.lk/',
  merchant_id_payhere: '1211147',
  gtag: 'UA-156957676-3',
  locale: 'en',
  homepage: 'default',
  favorite: 1,
  deals: 1,
  reviews: 1,
  payhere: 1,
  stripe: 0,
  push: 0,
  brand_filter: 1


  name: "NExotique",
  endpoint: "https://admin.nexotique.fr/api/",
  icon: "https://admin.ygold.lk/storage/app_icon.png",
  logo: "https://admin.nexotique.fr/storage/logo.png",
  title: "NExotique",
  primary_color: "#3CA1F2",
  secondary_color: "#68C0D3",
  accent_color: '#3CA1F2',
  currency: "€",
  storage_url: "https://admin.nexotique.fr/storage/",
  google_login_provider_id: "193707041852-n0fn4q2dte4la2l4h9g4pho50l5aka0c.apps.googleusercontent.com",
  facebook_app_id: "1242800786057247",
  apple_app_id: "ecommerce-angular.appslanka.lk",
  redirectURI: 'http://nexotique.fr',
  gtag: 'UA-156957676-3',
  locale: 'fr',
  homepage: 'default',
  favorite: 1,
  deals: 1,
  reviews: 1,
  payhere: 0,
  stripe: 1,
  push: 0,
  brand_filter: 1,
  logoBaseUrl: 'https://admin.nexotique.fr/storage/'
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
