<app-page-header
  [breadcrumbs]="[
    { label: 'Home', url: '../../' },
    { label: 'Terms And Conditions', url: '' }
  ]"
></app-page-header>

<div class="block">
  <div class="container" *ngIf="post.privacyPolicy != null">
    <div class="document">
      <div class="document__header">
        <h1 class="document__title">{{ post.privacyPolicy["title"] }}</h1>
        <div class="document__subtitle">
          This Agreement was last modified on 12 Aug 2020.
        </div>
      </div>
      <div
        class="document__content typography"
        [innerHTML]="post.privacyPolicy['content']"
      ></div>
    </div>
  </div>
</div>
