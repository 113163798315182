<!-- .block-slideshow -->
<div
  class="block-slideshow block d-lg-block d-none"
  [ngClass]="{
    'block-slideshow--layout--full': !withDepartments,
    'block-slideshow--layout--with-departments': withDepartments
  }"
>
  <div class="container">
    <div class="row">
      <div
        *ngIf="withDepartments"
        class="col-3 d-lg-block d-none"
        appDepartmentsArea
      ></div>
      <div
        class="col-12"
        [ngClass]="{
          'col-lg-12': !withDepartments,
          'col-lg-9': withDepartments
        }"
      >
        <div class="block-slideshow__body">
          <owl-carousel-o [options]="options" appOwlPreventClick>
            <ng-template *ngFor="let image of slides?.images" carouselSlide>
              <a class="block-slideshow__slide">
                <div
                  class="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                  [ngStyle]="{
                    'background-image': 'url(' + storage_URL + image + ')'
                  }"
                ></div>
                <div class="block-slideshow__slide-content">
                  <div
                    class="block-slideshow__slide-title"
                    [innerHTML]="
                      sanitizer.bypassSecurityTrustHtml(slides?.title)
                    "
                  ></div>
                  <div
                    class="block-slideshow__slide-text"
                    [innerHTML]="
                      sanitizer.bypassSecurityTrustHtml(slides?.subtitle)
                    "
                  ></div>
                </div>
              </a>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- .block-slideshow / end -->

<div
  class="block-slideshow block d-lg-none"
  [ngClass]="{
    'block-slideshow--layout--full': !withDepartments,
    'block-slideshow--layout--with-departments': withDepartments
  }"
>
  <div class="container">
    <div class="row">
      <div
        *ngIf="withDepartments"
        class="col-3 d-lg-block d-none"
        appDepartmentsArea
      ></div>
      <div
        class="col-12"
        [ngClass]="{
          'col-lg-12': !withDepartments,
          'col-lg-9': withDepartments
        }"
      >
        <div class="block-slideshow__body">
          <owl-carousel-o [options]="options" appOwlPreventClick>
            <ng-template *ngFor="let image of slides?.images_sm" carouselSlide>
              <a class="block-slideshow__slide">
                <div
                  class="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                  [ngStyle]="{
                    'background-image': 'url(' + storage_URL + image + ')'
                  }"
                ></div>
                <div class="block-slideshow__slide-content">
                  <div
                    class="block-slideshow__slide-title"
                    [innerHTML]="
                      sanitizer.bypassSecurityTrustHtml(slides?.title)
                    "
                  ></div>
                  <div
                    class="block-slideshow__slide-text"
                    [innerHTML]="
                      sanitizer.bypassSecurityTrustHtml(slides?.subtitle)
                    "
                  ></div>
                </div>
              </a>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</div>
