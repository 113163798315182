import { Component, Inject, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Slider } from 'src/app/shared/interfaces/slider';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-block-banner',
    templateUrl: './block-banner.component.html',
    styleUrls: ['./block-banner.component.scss']
})
export class BlockBannerComponent {
    @Input() ad: Slider[] = [];
    @Input() mobileAd: Slider[] = [];
    storage_URL = this.app.storage_url

    constructor(private app: ApplicationService,
        @Inject(DOCUMENT) private document: Document) { }

    openUrl() {
        const url = "https://dandenong.mymks.com.au/home"
        this.document.location.href = url;

    }
}
