<div class="mobile-header stickyMode mobile-header--sticky" #element>
  <div class="mobile-header__panel" #panelElement>
    <div class="container">
      <div class="mobile-header__body">
        <a class="mobile-header__logo" routerLink="/">
          <img
            src="https://dandenong.mymks.com.au/assets/icon/logo_h.png"
            alt="{{ app.name }}"
          />
        </a>

        <div class="mobile-header__indicators">
          <div
            (click)="openUrl()"
            class="indicator indicator--mobile-search indicator--mobile d-md-none"
          >
            Shop now
            <!-- <button class="mobile-header__menu-button" (click)="menu.open()">
              <app-icon name="cart-20" size="18x14"></app-icon>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
