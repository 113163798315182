<!-- <nav class="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow-nav">
  <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">Company name</a>
  <button
    class="navbar-toggler position-absolute d-md-none collapsed"
    type="button"
    data-toggle="collapse"
    data-target="#sidebarMenu"
    aria-controls="sidebarMenu"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <input
    class="form-control form-control-dark w-100"
    type="text"
    placeholder="Search"
    aria-label="Search"
  />
  <ul class="navbar-nav px-3">
    <li class="nav-item text-nowrap">
      <a class="nav-link" href="#">Sign out</a>
    </li>
  </ul>
</nav> -->

<!-- site -->
<!-- <app-mobile-menu></app-mobile-menu> -->
<div class="site">
  <!-- mobile site__header -->
  <header class="site__header d-lg-none">
    <app-mobile-header stickyMode="alwaysOnTop"></app-mobile-header>
  </header>
  <!-- desktop header -->
  <header class="site__header sticky-top d-lg-block d-none">
    <app-header [layout]="headerLayout"></app-header>
  </header>

  <div class="site__body">
    <router-outlet></router-outlet>
  </div>
  <app-totop></app-totop>
  <!-- <div class="fb-customerchat"></div> -->
</div>
