import { Component } from '@angular/core';
import { SettingsService } from '../../../../shared/services/settings.service';

@Component({
    selector: 'app-footer-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent {
    constructor(public setting: SettingsService) { }
}
