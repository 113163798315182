<div class="block about-us" *ngIf="post.aboutUs != null">
  <div
    class="about-us__image"
    style="background-image: url('../../../../../assets/images/aboutus.jpg')"
  ></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-xl-10">
        <div class="about-us__body">
          <h1 class="about-us__title title">{{ post.aboutUs["title"] }}</h1>
          <h1 class="about-us__title subtitle">
            WE ARE BUILDING A STRONG FAMILY
          </h1>

          <div
            class="about-us__text typography"
            [innerHTML]="post.aboutUs['content']"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
