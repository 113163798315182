<!-- .block-categories -->
<div class="how_it_work">Shop By Category</div>

<div
  class="block block--highlighted block-categories block-categories--layout--{{
    layout
  }}"
>
  <div class="container">
    <!-- <app-block-header [header]="header"></app-block-header> -->

    <div class="block-categories__list">
      <div
        *ngFor="let category of categories.slice(0, 6)"
        class="block-categories__item category-card category-card--layout--{{
          layout
        }}"
      >
        <div class="category-card__body">
          <div class="category-card__image">
            <a [routerLink]="root.category(category)"
              ><img src="{{ storage_URL }}{{ category.image }}" alt=""
            /></a>
          </div>
          <div class="category-card__content">
            <div class="category-card__name">
              <a
                routerLink="/shop/catalog/{{ category.id }}"
                [queryParams]="{ category: category.id }"
                >{{ category.name }}</a
              >
            </div>
            <ul class="category-card__links">
              <li *ngFor="let category of category.categories.slice(0, 5)">
                <a
                  routerLink="/shop/catalog/{{ category.id }}"
                  [queryParams]="{
                    category: category.category_id,
                    sub_category: category.id
                  }"
                  >{{ category.name }}</a
                >
              </li>
            </ul>
            <div class="category-card__all">
              <a
                routerLink="/shop/catalog/{{ category.id }}"
                [queryParams]="{ category: category.id }"
                >Show All</a
              >
            </div>
            <div class="category-card__products">
              {{ category.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- .block-categories / end -->
