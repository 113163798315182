<!-- .block-brands -->
<div class="block block-brands" *ngIf="brands?.length">
  <div class="container" *ngIf="showCarousel">
    <div class="block-brands__slider" #container>
      <owl-carousel-o [options]="carouselOptions" appOwlPreventClick>
        <ng-template *ngFor="let brand of brands" carouselSlide>
          <div class="block-brands__item">
            <a class="pointer" (click)="filterByBrand(brand)">
              <div
                [style.backgroundImage]="
                  'url(' + storage_URL + brand.image + ')'
                "
                class="thumbnail"
              ></div>

              <!-- <img
                [src]="storage_URL + brand.image"
                [alt]="brand.name"
                class="img"
            /> -->
            </a>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
<!-- .block-brands / end -->
