import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CartItem } from '../../../../shared/interfaces/cart-item';
import { RootService } from '../../../../shared/services/root.service';
import { OffcanvasCartService } from '../../../../shared/services/offcanvas-cart.service';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/shared/api/account.service';
import { ApplicationService } from 'src/app/shared/api/application.service';

export type DropcartType = 'dropdown' | 'offcanvas';

@Component({
    selector: 'app-header-dropcart',
    templateUrl: './dropcart.component.html',
    styleUrls: ['./dropcart.component.scss']
})
export class DropcartComponent {
    removedItems: CartItem[] = [];
    storage_URL = this.app.storage_url

    @Input() type: DropcartType = 'dropdown';

    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        public state: OffcanvasCartService,
        public root: RootService,
        private app: ApplicationService,
        private account: AccountService
    ) { }

    remove(item: CartItem): void {
        if (this.removedItems.includes(item)) {
            return;
        }

        this.removedItems.push(item);

    }

    close(): void {
        this.state.close();
    }
}
