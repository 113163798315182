import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AccountService } from 'src/app/shared/api/account.service';
import { SettingsService } from 'src/app/shared/services/settings.service';
declare var swal: any;

@Component({
    selector: 'app-footer-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent {
    emailFormControl = new FormControl('', [
        Validators.required,
        Validators.email,
    ]);
    subscription: { email?: string } = {};

    constructor(public setting: SettingsService,
        private account: AccountService) { }


    subscribe() {
        console.log(this.emailFormControl);

        this.subscription.email = this.emailFormControl.value;
        this.account.post(this.subscription, 'subscribes').then(async (result) => {
            this.emailFormControl.setValue('')
            swal("Thanks for subscribing! :)", "success");
        },
            async (err) => {
                swal("Opps...", "Please Try again!!!", "error");
            });
    }
}
