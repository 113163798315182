import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';

import { CarouselModule } from 'ngx-owl-carousel-o';

import { BlocksModule } from '../blocks/blocks.module';
import { SharedModule } from '../../shared/shared.module';
import { SiteRoutingModule } from './site-routing.module';
import { PageAboutUsComponent } from './pages/page-about-us/page-about-us.component';
import { PageContactUsComponent } from './pages/page-contact-us/page-contact-us.component';
import { PageTermsComponent } from './pages/page-terms/page-terms.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PagePrivacyComponent } from './pages/page-privacy/page-privacy.component';

@NgModule({
    declarations: [
        PageContactUsComponent,
        PageTermsComponent,
        PageAboutUsComponent,
        PagePrivacyComponent
    ],
    imports: [
        CommonModule,
        CarouselModule,
        ReactiveFormsModule,
        FormsModule,
        BlocksModule,
        SharedModule,
        SiteRoutingModule
    ]
})
export class SiteModule { }
