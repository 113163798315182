import { Injectable } from '@angular/core';
import { Product } from '../interfaces/product';
import { Category } from '../interfaces/category';
import { Brand } from '../interfaces/brand';

@Injectable({
    providedIn: 'root'
})
export class RootService {
    constructor() { }

    home(): string {
        return '/';
    }

    shop(): string {
        return `/shop/catalog`;
    }

    category(category: Partial<Category>): string {
        const basePath = this.shop();

        if (category.category_id == null) {
            return `${basePath}/${category.id}`;
        }
        else {
            return `${basePath}/${category.category_id}${'?filter_sub_category=' + category.id}`;
        }
    }

    brand(brand: Partial<Brand>): string {
        const basePath = this.shop();
        return `${basePath}/${brand.id}${'?filter_brand=' + brand.id}`;
    }

    product(product: Partial<Product>): string {
        const basePath = '/shop/products';
        if ('slug' in product) {
            return `${basePath}/${product.slug}`;
        }
        if ('id' in product) {
            return `${basePath}/${product.id}`;
        }
        throw Error('Provide product with "slug" or "id".');
    }

    deal(product: Partial<any>): string {
        const basePath = '/shop/deal';
        if ('id' in product) {
            return `${basePath}/${product.id}`;
        }
        throw Error('Provide product with "slug" or "id".');
    }

    wishlist(): string {
        return '/shop/wishlist';
    }

    cart(): string {
        return '/shop/cart';
    }

    checkout(): string {
        return '/shop/cart/checkout';
    }

    login(): string {
        return '/account/login';
    }

    notFound(): string {
        return `/site/not-found`;
    }
}
