<app-page-header
  [header]="'CONTACT'"
  [breadcrumbs]="[
    { label: 'HOME', url: '../../' },
    { label: 'CONTACT', url: '' }
  ]"
></app-page-header>

<div class="block">
  <div class="container">
    <div class="card mb-0">
      <div class="card-body contact-us">
        <div class="contact-us__container">
          <div class="row">
            <div class="col-12 col-lg-6 pb-4 pb-lg-0">
              <h4 class="contact-us__header card-title">Our Address</h4>

              <div class="contact-us__address">
                <p>
                  <strong>Dandenong</strong><br />
                  23 Pultney Street, VIC - 3175.<br />
                  Phone Number: +61 3 9701 3165
                </p>
                <p>
                  <strong>Ashwood</strong><br />
                  382 Warrigal Road, VIC - 3147.<br />
                  Phone Number: +61 3 9885 9932
                </p>
                <p>
                  <strong>Preston</strong><br />
                  258 High Street, VIC - 3072.<br />
                  Phone Number: +61 3 9416 9011
                </p>
                <p>
                  <strong>Epping</strong><br />
                  1/88 Cooper Street, VIC - 3076.<br />
                  Phone Number: +61 3 9424 9162
                </p>
                <p>
                  <strong>St Albans</strong><br />
                  276 Main Road East, VIC - 3021.<br />
                  Phone Number: +61 3 9366 4141
                </p>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <h4 class="contact-us__header card-title">
                {{ "SEND_US_MAIL" | translate }}
              </h4>

              <form [formGroup]="contactForm" (ngSubmit)="sendMessage()">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="form-name"> {{ "NAME" | translate }}</label>
                    <input
                      type="text"
                      formControlName="name"
                      id="form-name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="form-email">{{ "EMAIL" | translate }}</label>
                    <input
                      type="email"
                      formControlName="email"
                      id="form-email"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="form-subject">{{ "SUBJECT" | translate }}</label>
                  <input
                    type="text"
                    id="form-subject"
                    formControlName="subject"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="form-message">{{ "MESSAGE" | translate }}</label>
                  <textarea
                    id="form-message"
                    formControlName="message"
                    class="form-control"
                    rows="4"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  [ngClass]="{ 'btn-loading': sending }"
                  [disabled]="contactForm.invalid || sending === true"
                  class="btn btn-primary"
                >
                  {{ "SUBMIT" | translate }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
