<ul
  class="nav nav-tabs nav-justified mt-2 mt-lg-4 mb-0"
  role="tablist"
  style="min-height: 2.6rem"
>
  <li class="nav-item">
    <a
      class="nav-link font-weight-medium active"
      href="#nav-category"
      data-toggle="tab"
      role="tab"
      aria-controls="nav-category"
      aria-selected="true"
      >{{ "CATEGORIES" | translate }}</a
    >
  </li>
  <li class="nav-item" *ngIf="brands != undefined && brands.length > 0">
    <a
      class="nav-link font-weight-medium"
      href="#nav-brand"
      data-toggle="tab"
      role="tab"
      aria-controls="nav-brand"
      aria-selected="false"
      >{{ "BRANDS" | translate }}</a
    >
  </li>
</ul>

<div class="tab-content px-3 px-sm-0" id="nav-tabContent">
  <div
    class="max-height overflow-auto tab-pane fade show active"
    id="nav-category"
    role="tabpanel"
    aria-labelledby="nav-category-tab"
  >
    <div class="nav-panel__departments"></div>
    <!-- <app-header-departments></app-header-departments> -->
  </div>

  <div
    class="max-height overflow-auto tab-pane fade"
    id="nav-brand"
    role="tabpanel"
    aria-labelledby="nav-brand-tab"
  >
    <ul class="nav flex-column">
      <li class="nav-item" *ngFor="let item of brands">
        <a class="nav-link mouse-pointer" (click)="filterByBrand(item)">
          <span data-feather="file"></span>
          {{ item.name }}
        </a>
      </li>
    </ul>
  </div>
</div>
