import { Component, EventEmitter, Output, TemplateRef, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/shared/api/account.service';

@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent {
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
    modalRef: BsModalRef;

    constructor(
        private toastr: ToastrService,
        @Inject(PLATFORM_ID)
        private platformId: any,
        private router: Router,
        public account: AccountService,
        private translate: TranslateService,
        private modalService: BsModalService) { }

    lockout(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
        this.closeMenu.emit()
    }

    confirm() {
        this.modalRef.hide()
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('cartId');
            this.router.navigateByUrl('/');
        }
        this.translate.get(['LOGOUT_MSG']).subscribe(async translate => {
            this.toastr.info(translate.LOGOUT_MSG);
        })
    }
}
