<!-- .topbar -->
<!-- <div class="site-header__topbar topbar">
  <div class="topbar__container container">
    <div class="topbar__row">
      <div class="topbar__spring"></div>

      <div class="topbar__item topbar__item--link">
        <a class="topbar-link" routerLink="/">{{ "HOME" | translate }}</a>
      </div>
      <div class="topbar__item topbar__item--link">
        <a class="topbar-link" routerLink="/site/contact-us">{{
          "CONTACT" | translate
        }}</a>
      </div>
      <div class="topbar__item topbar__item--link">
        <a class="topbar-link" routerLink="/site/about-us">{{
          "ABOUT" | translate
        }}</a>
      </div>
      <div class="topbar__item topbar__item--link">
        <a class="topbar-link" routerLink="/site/privacy-policy">{{
          "PRIVACY" | translate
        }}</a>
      </div>
      <div class="topbar__item topbar__item--link">
        <a class="topbar-link" routerLink="/site/terms">{{
          "TERMS" | translate
        }}</a>
      </div>
    </div>
  </div>
</div> -->
<!-- .topbar / end -->

<div class="site-header__topbar topbar">
  <div class="topbar__container container">
    <div class="topbar__row">
      <div class="topbar__item topbar__item--link">
        <a class="topbar-link" routerLink="/">{{ "HOME" | translate }}</a>
      </div>
      <div class="topbar__item topbar__item--link">
        <a class="topbar-link" routerLink="/site/contact-us">{{
          "CONTACT" | translate
        }}</a>
      </div>
      <div class="topbar__item topbar__item--link">
        <a class="topbar-link" routerLink="/site/about-us">{{
          "ABOUT" | translate
        }}</a>
      </div>
      <div class="topbar__item topbar__item--link">
        <a class="topbar-link" routerLink="/site/privacy-policy">{{
          "PRIVACY" | translate
        }}</a>
      </div>
      <div class="topbar__item topbar__item--link">
        <a class="topbar-link" routerLink="/site/terms">{{
          "TERMS" | translate
        }}</a>
      </div>
      <div class="topbar__spring"></div>
      <div class="topbar__item">
        <ul class="footer-contacts__contacts">
          <li>
            <a
              class="footer-text site-footer__copyright"
              href="tel:+61397013165"
            >
              <i class="footer-contacts__icon fas fa-mobile-alt"></i>

              +61 3 9701 3165</a
            >
          </li>
        </ul>
        <!-- <a class="footer-text site-footer__copyright" href="tel:+61397013165">
          +61 3 9701 3165</a
        > -->
      </div>
    </div>
  </div>
</div>
