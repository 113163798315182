<div class="block p-t-64">
  <div class="container">
    <div class="not-found">
      <div class="not-found__content">
        <h1 class="display-404">404</h1>
        <h2 class="h3 mb-4">
          {{ "NOT_FOUND" | translate }}
        </h2>

        <a class="btn btn-primary btn-shadow" [routerLink]="root.home()">{{
          "RETURN_HOME" | translate
        }}</a>
      </div>
    </div>
  </div>
</div>
