<div class="site-footer__widget footer-contacts">
  <h5 class="footer-contacts__title">Contact Us</h5>

  <div class="footer-contacts__text">
    {{ setting.contactNote }}
  </div>

  <ul class="footer-contacts__contacts">
    <li>
      <i class="footer-contacts__icon fas fa-mobile-alt"></i>
      <a
        class="footer-text site-footer__copyright"
        href="tel:{{ setting.mobile }}"
        >{{ setting.mobile }}</a
      >
    </li>
    <li>
      <i class="footer-contacts__icon far fa-envelope"></i> {{ setting.email }}
    </li>
  </ul>
</div>
