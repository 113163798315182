import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
import { Variants } from '../../interfaces/product';
import { ApplicationService } from '../../api/application.service';



@Component({
  selector: 'app-variant-card',
  templateUrl: './variant-card.component.html',
  styleUrls: ['./variant-card.component.scss']
})
export class VariantCardComponent implements OnInit {
  @Input() variant: Variants;

  storage_URL = this.app.storage_url
  quantity: FormControl = new FormControl(1);
  addingToCart = false;

  constructor(
    private app: ApplicationService
  ) { }

  ngOnInit(): void {
  }
  addToCart(product): void {
    if (!this.addingToCart && product && this.quantity.value > 0) {
      this.addingToCart = true;

    }
  }
}
