<!-- <div class="site-header__top bg-white shadow-nav">
  <div class="container">
    <div class="nav-panel__row">
      <div class="nav-panel__logo">
        <a [routerLink]="root.home()">
          <img
            src="https://dandenong.mymks.com.au/assets/icon/logo_h.png"
            alt="{{ app.name }}"
          />
        </a>
      </div>
      <div class="nav-panel__indicators"></div>
    </div>
  </div>
</div> -->

<!-- <div class="site-header bg-white shadow-nav">
  <div class="site-header__middle container">
    <div class="site-header__logo">
      <a routerLink="/">
        <img
          src="https://dandenong.mymks.com.au/assets/icon/logo_h.png"
          alt="{{ app.name }}"
        />
      </a>
    </div>
    <div class="site-header__search"></div>
    <div class="site-header__phone">
      <div class="site-header__phone-title">Customer Service</div>
      <div class="site-header__phone-number">‭+61 3 9701 3165‬</div>
    </div>
  </div>
</div> -->

<div class="nav-panel stickyMode" [ngClass]="{ 'nav-panel--sticky': stickyMode }" #element>
  <div class="nav-panel__container container">
    <div class="nav-panel__row">
      <div class="nav-panel__logo">
        <a [routerLink]="root.home()">
          <img src="../../../../../assets/images/logo.png" alt="{{ app.name }}" style="height: 60px" />
        </a>
      </div>

      <!-- .nav-links -->
      <!-- .nav-links / end -->
      <div class="nav-panel__indicators">
        <app-indicator (click)="openUrl()" icon="cart-20"> </app-indicator>
      </div>
    </div>
  </div>
</div>